<template>
  <div class="home">
    <div class="banner">
      <h1 class="bannerText">Snel <mark>&</mark> Kwalitatief</h1>
    </div>
    <div class="infoCards">
      <div class="infoCard">
        <h2><font-awesome-icon class="icon" :icon="['fa', 'paint-brush']" />Schilderwerk</h2>
        <p>Je kunt bij mij terecht voor zowel binnen- als
          buiten schilderwerk. Ook lever ik saus en spuitwerk. Weet je nog niet zeker
          welke kleur je wilt? Ik geef graag kleuradvies!</p>
      </div>
      <div class="infoCard">
        <h2><font-awesome-icon class="icon" :icon="['fa', 'paint-brush']" />Glasweefselbehang</h2>
        <p>Je kunt bij mij ook terecht voor het aanbrengen van glasweefselbehang.</p>
      </div>
      <div class="infoCard">
        <h2><font-awesome-icon class="icon" :icon="['fa', 'paint-brush']" />Houtrotherstel</h2>
        <p>Heb je last van houtrot? Waar mogelijk is herstel ik dit graag voor je. Valt dit niet te herstellen los ik dit graag in overleg op.</p>
      </div>
    </div>
    <div class="productInfo">
      <h2>Professionele producten</h2>
      <p>Ik werk uitsluitend met professionele producten waarvan ik de kwaliteit kan garanderen en daardoor garantie kan geven voor een goed eindresultaat.</p>
    </div>
<!--    <div class="productInfoLogos">-->
<!--      <img src="../assets/sigma-logo.png" class="productInfoLogo"/>-->
<!--      <img src="../assets/sigma-logo.png" class="productInfoLogo"/>-->
<!--      <img src="../assets/sigma-logo.png" class="productInfoLogo"/>-->
<!--    </div>-->
    <div class="aboutWrapper">
      <div class="aboutInfo">
        <div class="aboutMe">
          <h2><font-awesome-icon class="icon" :icon="['fa', 'user']" />Over mij</h2>
          <p>
            Ik ben Marco van der Kooij, geboren in 1971 in Utrecht en hier ook altijd gewoond.
            Samen met mijn vrouw hebben we drie kinderen, 2 jongens en een meisje.
          </p>
          <p>
            Ooit als 16 jarige begonnen bij een schilder/glasbedrijf als schilder.
            Hierna bij diverse grote en kleine schilders en bouwbedrijven gewerkt.
          </p>
          <p>
            Nu ga ik door als zelfstandige!
          </p>
        </div>
        <img src="../assets/marco1.png"/>
      </div>
    </div>
    <div class="checklistAndReviews">
      <div class="checklist">
        <h2><font-awesome-icon class="icon" :icon="['fa', 'user']" />Waarom ik?</h2>
        <ul>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Vakbekwaamheid</li>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Kwaliteitswerk</li>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Gratis en duidelijke offerte</li>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Ervaring</li>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Vakkundig advies</li>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Kleuradvies</li>
          <li><font-awesome-icon class="icon" :icon="['fa', 'check']" />Nazorg</li>
        </ul>
      </div>
      <div class="reviews">
        <h2><font-awesome-icon class="icon" :icon="['fa', 'user']" />Reviews</h2>
        <p>Er zijn op dit moment nog geen reviews.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  mounted () {
    this.$store.commit('toggleTemp', false)
  }
}
</script>

<style lang="scss" scoped>
.home {
  text-align: center;

  h2 {
    margin-bottom: 0.75rem;

    .icon {
      margin-right: 0.5rem;
      color: var(--marco-blue);
    }
  }

  p {
    font-weight: 300;
  }

  .banner {
    padding: 5rem;

    background-image: url('../assets/banner.png');
    background-size: cover;

    .bannerText {
      font-weight: normal;
      color: white;
      text-shadow: 0px 3px 6px #00000050;

      mark {
        color: var(--marco-blue);
        background: none;
      }
    }
  }

  .infoCards {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin: 5rem auto;
    max-width: 125rem;

    .infoCard {
      text-align: left;
      margin: 2rem;
      padding: 3rem;
      border-radius: 10px;
      background: var(--card-bg-color);
    }
  }

  // Hiding icon when width < 1400px so it doesn't push infoCard title away.
  @media (max-width: 1400px) {
    .infoCard {
      .icon {
        display: none;
      }
    }
}

  .productInfo {
    width: 50%;
    max-width: 50rem;
    margin: auto;

    h2 {
      margin-bottom: 0.75rem;
    }
  }

  .productInfoLogos {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    width: 50%;
    margin: auto;
    max-width: 50rem;

    .productInfoLogo {
      max-width: 200px;
      margin: 2rem auto;
    }
  }

  .aboutWrapper {
    background: var(--card-bg-color);

    .aboutInfo {
      display: grid;
      grid-template-columns: 60% 40%;
      max-width: 100rem;
      margin: 5rem auto;
      padding: 5rem;

      img {
        margin: auto;
        max-width: 336px;
        border-radius: 10px;
      }

      .aboutMe {
        margin: auto;
        max-width: 50rem;
        text-align: left;

        h2 {
          margin-bottom: 0.75rem;
        }

        p {
          margin-bottom: 0.75rem;
          font-weight: 300;
        }
      }
    }
  }

  .checklistAndReviews {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10%;
    max-width: 100rem;
    margin: 5rem auto;

    .checklist {
      margin: auto;
      text-align: left;

      ul {
        list-style: none;

        li {
          font-weight: 300;
          margin-bottom: 0.5rem;

          .icon {
            color: var(--marco-blue);
            margin-right: 0.5rem;
          }
        }
      }
    }

    .reviews {
      text-align: left;
    }
  }
}

@media (max-width: 1024px) {
  .home {
    .banner {
      padding: 2.5rem;

      .bannerText {
        font-weight: normal;
        color: white;
        text-shadow: 0px 3px 6px #00000050;

        mark {
          color: var(--marco-blue);
          background: none;
        }
      }
    }

    .productInfo {
      width: 50%;
      max-width: 50rem;
      margin: auto;

      h2 {
        margin-bottom: 0.75rem;
      }
    }

    .productInfoLogos {
      display: block;
      margin: auto;

      .productInfoLogo {
        width: 33%;
        max-width: 7.5rem;
      }
    }

    .infoCards {
      display: block;
      margin: 2.5rem auto;
      max-width: 40rem;

      .infoCard {
        text-align: center;
        margin: 2rem;
        padding: 2rem;
        border-radius: 10px;
        background: var(--card-bg-color);
      }
    }

    .aboutWrapper {
      background: var(--card-bg-color);
      text-align: center;

      .icon {
        display: none;
      }

      .aboutInfo {
        display: block;
        grid-template-columns: 60% 40%;
        max-width: 100rem;
        padding: 2.5rem;
        text-align: center;
        margin: 2.5rem auto auto;

        img {
          margin: auto;
          max-width: 10rem;
          border-radius: 10px;
        }

        .aboutMe {
          margin: auto;
          max-width: 50rem;
          text-align: center;

          h2 {
            margin-bottom: 0.75rem;
          }

          p {
            margin-bottom: 0.75rem;
            font-weight: 300;
          }
        }
      }
    }

    .checklistAndReviews {
      display: block;
      text-align: center;
      margin: 2.5rem auto;

      .checklist {
        text-align: left;
        margin: auto;
        width: 50%;

        h2 {
          text-align: center;

          .icon {
            display: none;
          }
        }

        ul {
          list-style: none;

          li {
            font-weight: 300;
            margin-bottom: 0.5rem;
            font-size: var(--fs-s);

            .icon {
              margin-right: 0.5rem;
            }
          }
        }
      }

      .reviews {
        margin-top: 2.5rem;
        text-align: center;

        .icon {
          display: none;
        }
      }
    }
  }
}
</style>
