<template>
<footer class="footer">
  <div class="innerFooterWrapper">
    <div class="contact">
      <h2>Contact</h2>
      <p><font-awesome-icon class="icon" :icon="['fab', 'whatsapp']" />06-81845245</p>
      <p><font-awesome-icon class="icon" :icon="['fa', 'envelope']" />info@marcoschildert.nl</p>
    </div>
    <div class="socials">
      <h2>Socials</h2>
      <font-awesome-icon v-on:click="openFB" class="icon openFB" :icon="['fab', 'facebook']" />
    </div>
  </div>
  <div class="credits">
    <p>© Marco Schildert 2021</p>
  </div>
</footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    openFB() {
      window.open("https://www.fb.com/marcoschildert", '_blank');
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background: var(--marco-blue);
  color: white;
  padding: 5rem 10rem;

  .innerFooterWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 100rem;
    margin: auto;

    h2 {
      margin-bottom: 0.50rem;
    }

    .contact {
      margin: auto;

      .icon {
        margin-right: 0.75rem;
      }
    }

    .socials {
      margin: auto;

      .icon {
        font-size: 2.5rem;
      }

      .openFB {
        cursor: pointer;
      }
    }
  }

  .credits {
    border-top: 1px white solid;
    padding-top: 1.5rem;
    text-align: center;
    margin: 1.5rem auto;
  }
}

@media (max-width: 1024px) {
  .footer {
    padding: 1rem;

    .innerFooterWrapper {
      display: block;
      text-align: center;
      max-width: none;
      margin: auto;

      .contact {
        margin-bottom: 1rem;

        .icon {
          margin-right: 0.50rem;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}

</style>