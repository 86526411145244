<template>
<div class="menuWrapper">
  <h2 class="logo">MARCO<mark>Schildert</mark></h2>
  <div class="linksWrapper">
    <div class="links">
      <router-link to="/" exact>Home</router-link>
      <router-link to="/contact" exact>Contact</router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "MenuBar"
}
</script>

<style lang="scss" scoped>
.menuWrapper {
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: 50% 50%;

  .logo {
    text-align: left;
    font-weight: bold;
    color: var(--marco-blue);

    mark {
      font-weight: normal;
      background: none;
    }
  }

  .linksWrapper {
    margin: auto 0;

    .links {
      text-align: right;
      margin: auto 0;

      a {
        color: black;
        text-decoration: none;
        font-size: var(--fs-m);
        margin-left: 2rem;
      }

      a:hover {
        color: var(--marco-blue);
        transition: all 0.25s ease;
      }

      .router-link-active {
        color: var(--marco-blue);
      }
    }
  }
}

@media (max-width: 1024px) {
  .menuWrapper {
    display: block;
    text-align: center;
    padding: 0;

    .logo {
      text-align: center;
      font-size: var(--fs-xl);
      padding: 0.5rem;
    }

    .linksWrapper {
      background: var(--card-bg-color);

      .links {
        text-align: center;
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        border-radius: 10px;
        max-width: 15rem;
        margin: auto;

        a {
          text-align: center;
          margin: auto;
        }
      }
    }
  }

}
</style>